import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import App from './app';
import { theme } from './shared/theme';
import { ConfigProvider } from 'antd';
import store from './application/store';
import './styles/account/authenticate.css'
import "react-phone-number-input/style.css";
import "./styles/phone_number.css";
import "./styles/form.css";
import "./styles/input.css";
import "./styles/button.css";
import './styles/account/oz.css'
import './translations';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ConfigProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
);