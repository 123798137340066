import ApiEngine from "../common/api_engine";
import { ApiKey, VendorApiUrl, _VENDOR_HEADER_CONFIG ,VendorHeaderKey,SessionKey} from "../common/constant";
import { createMultiPartFormBody } from "../common/util";
import store from '../application/store';

/// <summary>
/// Author: YJ
/// </summary>
export default class TransactionService {
    /// <summary>
    /// Author: SY
    /// </summary>
    getHeaderConfig() {
        return {
            ..._VENDOR_HEADER_CONFIG,
            [VendorHeaderKey._XCHANGE_KEY]: sessionStorage.getItem(SessionKey._XCHANGE_KEY) ?? ''
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async initTopUp(phoneNumber, transactionId) {
        var state = store.getState().vendorState;

        var data = {
            transactionId: transactionId,
            phoneNumber: phoneNumber
        }

        var response = await ApiEngine.post(VendorApiUrl._INIT_TOPUP, data, { headers: { ...state.headerConfig } });

        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY];
        }

        return response;
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async topUpCallback(orderId, status, amount, currency) {
        var data = {
            orderId: orderId,
            status: status,
            amount: amount,
            currency: currency
        }

        var response = await ApiEngine.post(VendorApiUrl._TOPUP_CALLBACK, createMultiPartFormBody(data), { headers: { [ApiKey._CONTENT_TYPE]: ApiKey._FORM_DATA } });

        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY];
        }

        return response;
    }

    /// <summary>
    /// Author: SY
    /// </summary>
    async initTransaction() {
        var response = await ApiEngine.post(VendorApiUrl._INIT_TRANSACTION, {}, { headers: this.getHeaderConfig() });
        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY]
        }

        return response;
    }

    /// <summary>
    /// Author: SY
    /// </summary>
    async checkPinExist() {
        var response = await ApiEngine.get(VendorApiUrl._CHECK_PIN, { headers: this.getHeaderConfig() });
        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY];
        }
        return response;
    }

    /// <summary>
    /// Author: SY
    /// </summary>
    async createPin(params) {
        var response = await ApiEngine.put(VendorApiUrl._CREATE_PIN, params, { headers: this.getHeaderConfig() });
        return response;
    }

    /// <summary>
    /// Author: SY
    /// </summary>
    async validatePin(params) {
        var response = await ApiEngine.post(VendorApiUrl._VALIDATE_PIN, params, { headers: this.getHeaderConfig() });
        return response;
    }

    /// <summary>
    /// Author: SY
    /// </summary>
    async initPayment(params) {
        var response = await ApiEngine.post(VendorApiUrl._PAYMENT_INIT, params, { headers: this.getHeaderConfig() });
        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY];
        }
        return response;
}
}