import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { _MEMBER_PATH, Mode, Page } from '../common/constant';
import _ROUTES from './page';
import { setMode } from '../application/action/mode_action';

/// <summary>
/// Author: SY
/// </summary>
const determineMode = (pathname) => {
    if (pathname.startsWith(_MEMBER_PATH)) { 
        return Mode._NORMAL;
    } else {
        return  Mode._SDK;
    }
};

/// <summary>
/// Author: Nelson
/// </summary>
const Content = ({ mode, setMode }) => {
    const location = useLocation();
    const navigate = useNavigate();

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        const currentMode = determineMode(location.pathname);

        if (!mode) {
            setMode(currentMode);
        } else if (currentMode !== mode) {
            navigate(Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL);
        }
    }, [location.pathname, mode, navigate, setMode]);

    const routesToUse = _ROUTES[mode];
    if (!routesToUse) {
        return null;
    }
    
    return (
        <Routes location={location}>
            {
                routesToUse.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={route.component} />
                ))
            }
        </Routes>
    )
};

const mapStateToProps = state => ({
    mode: state.modeState.mode,
});

const mapDispatchToProps = {
    setMode,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Content);
