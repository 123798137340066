import React, { forwardRef, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input'
import { stringIsNullOrEmpty } from '../common/util'
import { isValidPhoneNumber } from 'react-phone-number-input';

/// <summary>
/// Author: Nelson
/// </summary>
const PhoneNumber = forwardRef((prop, ref) => {
    const { value, onChange, error, isValid } = prop;

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(value) && isValid) {
            isValid(isValidPhoneNumber(value));
        }
    }, [value])

    return (
        <div className='cust-phone-number-main-wrapper'>
            <PhoneInput
                defaultCountry='MY'
                international
                ref={ref}
                placeholder="Enter phone number"
                value={value}
                countryCallingCodeEditable={false}
                countries={['MY']}
                limitMaxLength
                onChange={onChange} />
            {error && <span className='form-error'>{error.message}</span>}
        </div>
    )
});

export default PhoneNumber;