import { Page, TransactionMethod } from '../../../common/constant';

/// <summary>
/// Author: SY
/// </summary>
export const handleNavigate = (paymentMethods, selectedPaymentMethod, amount, _navigate) => {
    const selectedMethod = paymentMethods.find(pm => pm.data === selectedPaymentMethod);
    if (selectedMethod) {
        switch (selectedMethod.methodId) {
            case TransactionMethod._ONLINE_BANKING:
                _navigate(Page._PAYMENT, {
                    state: {
                        amount: amount,
                        paymentMethod: selectedMethod
                    }
                });
                break;
            case TransactionMethod._DUITNOW:
                //  navigation for DuitNow
                break;
            case TransactionMethod._EWALLET:
                _navigate(Page._EWALLET_PIN, {
                    state: {
                        amount: amount,
                        paymentMethod: selectedMethod
                    }
                });
                break;
            default:
                console.log("You are choosing:", selectedMethod.data);
        }
    } else {
        console.error("No payment method selected or found.");
    }
};

