// export const _BASE_URL = 'http://localhost:26329';
// export const _BASE_URL = 'https://diana.vpass.my';
export const _BASE_URL = 'https://app-api.vpass.my';
export const _VENDOR_SUB_URL = "/api/v1";

export const _STANDARD_MOBILE_VIEW_WIDTH_SIZE = 768;
export const _MEMBER_PATH = '/member';

/// <summary>
/// Author: Nelson
/// </summary>
export class ApiKey {
    static _SUCCESS_KEY = "success";
    static _MESSAGE_KEY = "message";
    static _COUNT_KEY = "totalCount";
    static _DATA_KEY = "data";
    static _CODE_KEY = "code";
    static _ACCEPT_LANGUAGE = 'Accept-Language';
    static _CONTENT_TYPE = 'Content-Type';
    static _ACCEPT = "Accept";
    static _APPLICATION_JSON = "application/json";
    static _XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _FORM_DATA = "multipart/form-data";
    static _POST = "POST";
    static _GET = "GET";
}

/// <summary>
/// Author: Nelson
/// </summary>
export class Page {
    static _DEFAULT = '/';
    static _BIOMETRIC_VERIFICATION_MAIN_URL = '/account/verification';
    // static _BIOMETRIC_VERIFICATION_URL = this._BIOMETRIC_VERIFICATION_MAIN_URL + '/:identifier/:code/:signature/:phoneNumber';
    static _VERIFICATION_RESULT_URL = this._BIOMETRIC_VERIFICATION_MAIN_URL + '/status';
    static _INIT_TOPUP_URL = '/topup/init/:identifier/:code/:signature/:phoneNumber/:transactionId';
    static _PROCESS_TOPUP_URL = '/topup/process/:txnId/:amount';
    static _TOPUP_RESULT_URL = '/topup/result/:orderId/:status/:amount/:currency';
    static _VENDOR_ACC_AUTHENTICATE_URL = '/account/authenticate';
    static _VENDOR_ACC_AUTHENTICATE_EXPIRED_URL = '/account/authenticate/session/expired';
    static _TOPUP_SELECTION = '/topup';
    static _PAYMENT_LIST = '/payment/list';
    static _PAYMENT = '/payment';
    static _PAYMENT_RESULT = '/payment/result';
    static _EWALLET_PIN = '/ewallet/pin';
}

/// <summary>
/// Author: Nelson
/// </summary>
export class VendorApiUrl {
    static _REGISTER = _VENDOR_SUB_URL + '/user/register';
    static _ESTABLISH_AUTH_SESSION = _VENDOR_SUB_URL + '/user/establish';
    static _TERMINATE_AUTH_SESSION = _VENDOR_SUB_URL + '/user/terminate';
    static _INIT_TOPUP = _VENDOR_SUB_URL + '/transaction/topup';
    static _TOPUP_CALLBACK = _VENDOR_SUB_URL + '/callback/razer';

    static _INIT_AUTH = _VENDOR_SUB_URL + '/account/auth/init';
    static _REQUEST_AUTH_OTP = _VENDOR_SUB_URL + '/account/auth/request/:phoneNumber';
    static _VERIFY_AUTH_OTP = _VENDOR_SUB_URL + '/account/auth/confirm/:phoneNumber/:otp';

    static _INIT_BIOMETRIC_SESSION = _VENDOR_SUB_URL + "/account/auth/verification/init";
    static _SUBMIT_BIOMETRIC_REQUEST = _VENDOR_SUB_URL + "/account/auth/verification/request";
    static _TERMINATE_BIOMETRIC_SESSION = _VENDOR_SUB_URL + "/account/auth/verification/terminate";

    static _INIT_TRANSACTION = _VENDOR_SUB_URL + '/transaction/init';
    static _CHECK_PIN = _VENDOR_SUB_URL + '/transaction/check/pin/exist';
    static _CREATE_PIN = _VENDOR_SUB_URL + '/transaction/create/pin';
    static _VALIDATE_PIN = _VENDOR_SUB_URL + '/transaction/validate/pin';

    static _PAYMENT_INIT = _VENDOR_SUB_URL + '/payment/init';
}

/// <summary>
/// Author: Nelson
/// </summary>
export class SessionKey {
    static _VENDOR_HEADER_CONFIG = "vendorHeaderConfig";
    static _XCHANGE_KEY = "xchangeKey";
    static _PHONE_NUMBER = 'tempPhoneNumber';
    static _LANGUAGE = "Language";
}

/// <summary>
/// Author: Nelson
/// </summary>
export class VendorHeaderKey {
    static _XCHANGE_KEY = 'xChangeKey';
    static _CODE_KEY = 'code';
}

/// <summary>
/// Author: Nelson
/// </summary>
export class VendorParamKey {
    static _XCHANGE_KEY = 'key';
    static _CODE_KEY = 'code';
}

/// <summary>
/// Author: Nelson
/// </summary>
export const _VENDOR_HEADER_CONFIG = {
    [VendorHeaderKey._XCHANGE_KEY]: '',
    [VendorHeaderKey._CODE_KEY]: ''
}

/// <summary>
/// Author: Nelson
/// </summary>
export class RouteKey {
    static _PREV_URL = "prevUrl";
    static _ACTIVE_URL = "activeUrl";
}

/// <summary>
/// Author: Nelson
/// </summary>
export class Language {
    static _CHINESE = "zh";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "ms";
}

/// <summary>
/// Author: Nelson
/// </summary>
export class HttpStatusCode {
    static _OK = 200;
    static _BAD_REQUEST = 400;
    static _UNAUTHORIZED = 401;
    static _FORBIDDEN = 403;
    static _NOT_FOUND = 404;
    static _UNSUPPORTED_MEDIA_TYPE = 415;
    static _INTERNAL_SERVER_ERROR = 500;
    static _AUTHENTICATION_TIMEOUT = 419;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class Status {
    static _ACTIVE = 1;
    static _INACTIVE = -1;
    static _SUSPENDED = -2;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class DateTimeFormat {
    static _DATE = "YYYY-MM-DD";
    static _TIME = "HH:mm:ss";
    static _DATE_TIME = "YYYY-MM-DD HH:mm:ss";
}

/// <summary>
/// Author: Nelson
/// </summary>
export class ValidationPattern {
    static _EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static _PHONE_NUMBER_REGEX = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class LogLevel {
    static _AUDIT = "AUDIT";
    static _DEBUG = "DEBUG";
    static _INFO = "INFO";
    static _WARN = "WARN";
    static _ERROR = "ERROR";
    static _FATAL = "FATAL";
}

/// <summary>
/// Author: Nelson
/// </summary>
export class ApiRequestMethod {
    static _GET = "GET";
    static _POST = "POST";
    static _PUT = "PUT";
    static _DELETE = "DELETE";
}

/// <summary>
/// Author: Nelson
/// </summary>
export class CallbackResponseType {
    static _NONE = -1;
    static _HTML = 1;
    static _URL = 2;
    static _JAVASCRIPT = 3;
}

/// <summary>
/// Author: YJ
/// </summary>
export class WalletHistoryStatus {
    static _PENDING = 0;
    static _SUCCESS = 1;
    static _FAILED = 2;
}

/// <summary>
/// Author: Nelson
/// </summary>
export class BiometricStatus {
    static _PENDING = 1;
    static _QUALIFIED = 2;
    static _UNQUALIFIED = -1;
}

/// <summary>
/// Author: SY
/// </summary>
export const Mode = {
    _NORMAL: 'NmjfYDGmgwsNqFH',
    _SDK: 'SEtQYeVYdGbMqhy'
};

/// <summary>
/// Author: SY
/// </summary>
export class TransactionMethod {
    static _ALL = 1;
    static _DUITNOW = 2;
    static _EWALLET = 3;
    static _ONLINE_BANKING = 4;
};

/// <summary>
/// Author: SY
/// </summary>
export class PaymentStatus {
    static _PENDING = 1;
    static _SUCCESS = 2;
    static _FAILED = -2;
}
