import React, { useEffect } from 'react';
import { ApiKey, Page } from '../../common/constant';
import TransactionService from '../../services/transaction_service';
import { useLocation, useNavigate } from 'react-router-dom';
import { colorError } from '../../shared/theme';
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: SY
/// </summary>
const PaymentGateway = () => {
    const _transactionService = new TransactionService();
    const _location = useLocation();
    const { amount, paymentMethod } = _location.state || {};
    const _navigate = useNavigate();
    const { t } = useTranslation();

    /// <summary>
    /// Author: SY
    /// </summary>
    useEffect(() => {
        (
            async () => {
                try {
                    var response = await _transactionService.initPayment({
                        amount: amount,
                        paymentCode: paymentMethod['data'],
                        methodId: paymentMethod['methodId']
                    });
                    document.getElementById('paymentContainer').innerHTML = response[ApiKey._DATA_KEY];
                    document.getElementById('gpayForm').submit();
                } catch (error) {
                    var contentState = {
                        title: t("PAYMENT_FAILED"),
                        message: t("CONTACT_ADMIN_FOR_HELP"),
                        icon: {
                            name: 'times-circle',
                            color: colorError
                        }
                    };
                    _navigate(Page._VERIFICATION_RESULT_URL, { state: { ...contentState }, replace: true });
                }
            }
        )();
    }, [amount, paymentMethod]);

    return (
        <div id="paymentContainer">
        </div>
    );
};

export default PaymentGateway;
