import React from 'react';
import { Mode, Page } from '../common/constant';
import Biometric from '../pages/verification/biometric';
import VerificationResult from '../pages/result/verification_result';
import InitTopUp from '../pages/razer/init_topup';
import ProcessTopUp from '../pages/razer/process_topup';
import TopUpResult from '../pages/razer/topup_result';
import Authenticate from '../pages/account/authenticate';
import AuthExpiry from '../pages/result/auth_expired';
import TopupSelection from '../pages/transaction/topup_selection';
import PaymentGateway from '../pages/payment/payment_gateway';
import PaymentResult from '../pages/payment/result';
import PaymentSelection from '../pages/transaction/payment_selection';
import Pin from '../pages/transaction/pin';

/// <summary>
/// Author: SY
/// </summary>
export const _ROUTES = {
    [Mode._NORMAL]: [
        {
            path: Page._DEFAULT,
            title: 'AUTHENTICATE',
            authRequired: false,
            component: <Authenticate />
        },
        {
            path: Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL,
            title: 'SESSION_EXPIRED',
            authRequired: false,
            component: <AuthExpiry />
        }
    ],
    [Mode._SDK]: [
        {
            path: Page._PAYMENT_LIST,
            title: 'PAYMENT_LIST',
            authRequired: false,
            component: <PaymentSelection />
        },
        {
            path: Page._TOPUP_SELECTION,
            title: 'TOPUP',
            authRequired: false,
            component: <TopupSelection />
        },
        {
            path: Page._EWALLET_PIN,
            title: 'PIN',
            authRequired: false,
            component: <Pin />
        },
        {
            path: Page._PAYMENT_RESULT,
            title: 'PAYMENT RESULT',
            authRequired: false,
            component: <PaymentResult />
        },
        {
            path: Page._PAYMENT,
            title: 'PAYMENT',
            authRequired: false,
            component: <PaymentGateway />
        },
        {
            path: Page._BIOMETRIC_VERIFICATION_MAIN_URL,
            title: 'VERIFICATION',
            authRequired: false,
            component: <Biometric />
        },
        {
            path: Page._VERIFICATION_RESULT_URL,
            title: 'VERIFICATION_RESULT',
            authRequired: false,
            component: <VerificationResult />
        },
        {
            path: '*',
            title: 'VERIFICATION',
            authRequired: false,
            component: <VerificationResult />
        },
        {
            path: Page._INIT_TOPUP_URL,
            title: 'INIT_TOPUP',
            authRequired: false,
            component: <InitTopUp />
        },
        {
            path: Page._PROCESS_TOPUP_URL,
            title: 'PROCESS_TOPUP',
            authRequired: false,
            component: <ProcessTopUp />
        },
        {
            path: Page._TOPUP_RESULT_URL,
            title: 'TOPUP_RESULT',
            authRequired: false,
            component: <TopUpResult />
        },
        {
            path: Page._VENDOR_ACC_AUTHENTICATE_URL,
            title: 'AUTHENTICATE',
            authRequired: false,
            component: <Authenticate />
        },
        {
            path: Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL,
            title: 'SESSION_EXPIRED',
            authRequired: false,
            component: <AuthExpiry />
        }
    ]
};

export default _ROUTES;