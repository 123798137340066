import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isObjectEmpty, stringIsNullOrEmpty } from '../../common/util';
import { _STANDARD_MOBILE_VIEW_WIDTH_SIZE, _VENDOR_HEADER_CONFIG, ApiKey, Page } from '../../common/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { setupAuthHeader } from '../../application/action/vendor_action';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'
import TransactionService from '../../services/transaction_service';

/// <summary>
/// Author: YJ
/// </summary>
const InitTopUp = () => {
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _navigate = useNavigate();
    const _transactionService = new TransactionService();

    const { code, identifier, signature, phoneNumber, transactionId } = useParams();

    useEffect(() => {
        (async () => {
            if (
                stringIsNullOrEmpty(code)
                ||
                stringIsNullOrEmpty(identifier)
                ||
                stringIsNullOrEmpty(phoneNumber)
                ||
                stringIsNullOrEmpty(signature)
                ||
                stringIsNullOrEmpty(transactionId)
            ) {
                throw 'Invalid session';
            }

            var config = _VENDOR_HEADER_CONFIG;

            config.Code = code;
            config.Identifier = identifier;
            config.Signature = signature;
            config.DeviceId = "KUTSE35NZMRDX90J8F17";
            config.PhoneNumber = phoneNumber;

            _dispatch(setupAuthHeader(config));

            try {
                var response = await _transactionService.initTopUp(phoneNumber, transactionId);

                if (!isObjectEmpty(response[ApiKey._DATA_KEY]) 
                    && !stringIsNullOrEmpty(response[ApiKey._DATA_KEY]["constructedUrl"])) {
                        _navigate(response[ApiKey._DATA_KEY]["constructedUrl"]);
                }
                else {
                    throw "Error";
                }
            }
            catch(err) {
                _navigate(Page._DEFAULT, { state: { status: false, title: 'Error', message: err }, replace: true });
            }
        })();
    }, []);

    return (
        <div style={{ padding: '0 20px', height: '100%', maxWidth: _STANDARD_MOBILE_VIEW_WIDTH_SIZE, margin: '0 auto', textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
        </div>
    );
};

export default InitTopUp;