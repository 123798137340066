import React from 'react';
import { List, Image, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { colorPrimary } from '../shared/theme';
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: SY
/// </summary>
const PaymentMethodList = ({ paymentMethods, selectedPaymentMethod, onPaymentMethodClick }) => {
    const { t } = useTranslation();

    /// <summary>
    /// Author: SY
    /// </summary>
    const groupedPaymentMethods = paymentMethods.reduce((groups, method) => {
        const group = method.methodName;
        if (!groups[group]) {
            groups[group] = [];
        }
        groups[group].push(method);
        return groups;
    }, {});

    return (
        <>
            <Typography.Title level={4} >{t("PAYMENT_METHOD")}</Typography.Title>
            {Object.keys(groupedPaymentMethods).map(group => (
                <div key={group} style={{ marginBottom: '20px' }}>
                    <Typography.Title level={5} style={{ marginBottom: '10px' }}>{group}</Typography.Title>
                    <List
                        itemLayout="horizontal"
                        dataSource={groupedPaymentMethods[group]}
                        renderItem={method => (
                            <List.Item
                                style={{
                                    padding: '10px 0',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid #ddd',
                                    backgroundColor: 'white'
                                }}
                                onClick={() => onPaymentMethodClick(method)}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {method.image && (
                                            <div style={{ marginRight: '10px' }}>
                                                <Image
                                                    src={method.image}
                                                    alt={method.name}
                                                    width={40}
                                                    height={40}
                                                    preview={false}
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <Typography.Text style={{ margin: 0 }}>{method.name}</Typography.Text>
                                        </div>
                                        {method.balance && (
                                            <div style={{ marginRight: '10px' }}>
                                                <Typography.Text style={{ margin: 0 }}>: {method.balance}</Typography.Text>
                                            </div>
                                        )}
                                    </div>

                                    {selectedPaymentMethod === method.data && (
                                        <CheckCircleOutlined style={{ color: colorPrimary, fontSize: '20px' }} />
                                    )}
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            ))}
        </>
    );
};

export default PaymentMethodList;
