import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { stringIsNullOrEmpty } from '../../common/util';
import { _STANDARD_MOBILE_VIEW_WIDTH_SIZE, ApiKey, Page, WalletHistoryStatus } from '../../common/constant';
import TransactionService from '../../services/transaction_service';

/// <summary>
/// Author: YJ
/// </summary>
const TopUpResult = () => {
    var _location = useLocation();
    var _navigate = useNavigate();

    const _transactionService = new TransactionService();

    const { orderId, status, amount, currency } = useParams();

    useEffect(() => {
        (async() => {
            if (
                stringIsNullOrEmpty(orderId)
                ||
                stringIsNullOrEmpty(status)
                ||
                stringIsNullOrEmpty(amount)
                ||
                stringIsNullOrEmpty(currency)
            ) {
                throw 'Invalid session';
            }

            try {
                var response = await _transactionService.topUpCallback(orderId, status, amount, currency);

                if (response[ApiKey._DATA_KEY]["status"] == WalletHistoryStatus._SUCCESS) {
                    _navigate(Page._DEFAULT, { state: { status: true, title: 'Success', message: "Top up success", redirect: response[ApiKey._DATA_KEY]["redirectUrl"] }, replace: true });
                }
                else {
                    _navigate(Page._DEFAULT, { state: { status: false, title: 'Error', message: "Top up failed", redirect: response[ApiKey._DATA_KEY]["redirectUrl"] }, replace: true });
                }
            }
            catch(err) {
                _navigate(Page._DEFAULT, { state: { status: false, title: 'Error', message: err }, replace: true });
            }
        })();
    }, []);

    return (
        <>
            <div style={{ padding: '0 20px', height: '100%', maxWidth: _STANDARD_MOBILE_VIEW_WIDTH_SIZE, margin: '0 auto', textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            </div>
        </>
    );
};

export default TopUpResult;