import ApiEngine from "../common/api_engine";
import { ApiKey, SessionKey, VendorApiUrl, VendorHeaderKey, _VENDOR_HEADER_CONFIG } from "../common/constant";
import { createMultiPartFormBody } from "../common/util";

/// <summary>
/// Author: Nelson
/// </summary>
export default class MemberService {
    /// <summary>
    /// Author: Nelson
    /// </summary>
    getHeaderConfig() {
        return {
            ..._VENDOR_HEADER_CONFIG,
            [VendorHeaderKey._XCHANGE_KEY]: sessionStorage.getItem(SessionKey._XCHANGE_KEY) ?? ''
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async initAuth() {
        var response = await ApiEngine.post(VendorApiUrl._INIT_AUTH, {}, { headers: this.getHeaderConfig() });

        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY]
        }

        return response;
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async requestAuthOtp(phoneNumber) {
        var response = await ApiEngine.post(VendorApiUrl._REQUEST_AUTH_OTP.replace(':phoneNumber', phoneNumber), {}, { headers: this.getHeaderConfig() });

        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY]
        }

        return response;
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async verifyAuthOtp(otp) {
        return await ApiEngine.post(VendorApiUrl._VERIFY_AUTH_OTP.replace(':phoneNumber', sessionStorage.getItem(SessionKey._PHONE_NUMBER).replace('+', '')).replace(':otp', otp), {}, { headers: this.getHeaderConfig() });
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async initBiometricSession() {
        var response = await ApiEngine.post(VendorApiUrl._INIT_BIOMETRIC_SESSION, {}, { headers: this.getHeaderConfig() });

        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY]
        }

        return response;
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async submitBiometricRequest(data = { validationId: null, sourceUrl: null, sourceData: null }) {
        var response = await ApiEngine.post(VendorApiUrl._SUBMIT_BIOMETRIC_REQUEST, createMultiPartFormBody(data), { headers: { ...this.getHeaderConfig(), "Content-Type": ApiKey._FORM_DATA } });

        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY]
        }

        return response;
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async terminateBiometricSession() {
        var response = await ApiEngine.delete(VendorApiUrl._TERMINATE_BIOMETRIC_SESSION, { headers: this.getHeaderConfig() });

        if (!response[ApiKey._SUCCESS_KEY]) {
            throw response[ApiKey._MESSAGE_KEY]
        }

        return response;
    }
}