import { _VENDOR_HEADER_CONFIG } from "../../common/constant";
import { _SETUP_AUTH_HEADER, _UPDATE_BIOMETRIC_DATA } from "../action/vendor_action";

const _INITIAL_STATE = {
    headerConfig: _VENDOR_HEADER_CONFIG,
    source: {}
};

/// <summary>
/// Author: Nelson
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _SETUP_AUTH_HEADER:
            return {
                ...state,
                headerConfig: { ...action.headerConfig }
            }
            break;
        case _UPDATE_BIOMETRIC_DATA:
            return {
                ...state,
                source: { ...action.source }
            }
            break;
        default:
            return state;
            break;
    }
}