import _ from 'lodash';
import { isValidPhoneNumber } from 'react-phone-number-input';

/// <summary>
/// Author: YJ
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data == null || (typeof data != 'boolean' && data == '') || data == undefined || (typeof data == 'string' && !data.trim());
}

/// <summary>
/// Author : YJ
/// Convert array into form that use to post data
/// </summary>
export function createFormUrlEncoded(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : YJ
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

/// <summary>
/// Author: YJ
/// </summary>
export function isObjectEmpty(data) {
    return data === null || data === undefined || Object.keys(data).length === 0;
}

/// <summary>
/// Author: Nelson
/// </summary>
export function numberWithCurrencyFormat(value, decimal = 2) {
    const CURRENCY_DECIMAL = 2;
    var convertedNumber = parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

    if (decimal > CURRENCY_DECIMAL) {
        let afterDecimalNumber = convertedNumber.split('.');
        let decimalDiff = decimal - CURRENCY_DECIMAL;

        if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
            let processNumber = afterDecimalNumber[1];

            for (let i = processNumber.length - 1; (i > 0 && decimalDiff > 0); i--) {
                if (processNumber.charAt(i) == '0') {
                    processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
                }
                else {
                    break;
                }

                decimalDiff--;
            }

            convertedNumber = afterDecimalNumber[0] + "." + processNumber;
        }
    }

    return convertedNumber
}

/// <summary>
/// Author: Nelson
/// </summary>
export function constructQueryString(url, filterObj) {
    if (!stringIsNullOrEmpty(url) && !isObjectEmpty(filterObj)) {
        url += (url.includes('?') ? '&' : '?') + Object.keys(filterObj).filter(key => !stringIsNullOrEmpty(filterObj[key])).map((key) => key + "=" + encodeURIComponent(filterObj[key])).join('&')
    }

    return url;
}

/// <summary>
/// Author: Nelson
/// </summary>
export function getRandomNumber(max = 10000000) {
    return Math.ceil(Math.random() * max);
}

/// <summary>
/// Author: Nelson
/// </summary>
export function sortObjectProperties(object) {
    return Object.keys(object).sort().reduce((obj, key) => { obj[key] = object[key]; return obj; }, {});
}

/// <summary>
/// Author: Nelson
/// </summary>
export function constructDropdownOptions(
    data = [],
    responseTemplate = {
        label: '',
        value: ''
    },
    sortAscending = true,
    groupOptions = {
        childrenSortOptions: {
            orders: [],
            keys: []
        },
        childrenResponseTemplate: {
            label: '',
            value: ''
        }
    }) {
    var options = [];

    if (groupOptions && !stringIsNullOrEmpty(groupOptions['childrenResponseTemplate']['label']) && !stringIsNullOrEmpty(groupOptions['childrenResponseTemplate']['value'])) {
        var group = _.groupBy(data, responseTemplate['label']);
        var keys = Object.keys(group).sort()

        if (!sortAscending) {
            Object.keys(group).sort().reverse();
        }

        options = keys.map((key) => {
            var obj = {};
            obj['label'] = key;

            if (groupOptions.hasOwnProperty('sortOptions') && groupOptions['childrenSortOptions']['keys']['length'] > 0) {
                if (groupOptions['childrenSortOptions'].hasOwnProperty('orders')) {
                    groupOptions['childrenSortOptions']['orders'] = [];
                }

                for (var i = 0; i < groupOptions['childrenSortOptions']['keys']['length']; i++) {
                    if (i + 1 > groupOptions['childrenSortOptions']['orders']['length']) {
                        groupOptions['childrenSortOptions']['orders'].push('asc');
                    }
                }

                obj['options'] = _.orderBy(obj['value'], groupOptions['childrenSortOptions']['keys'], groupOptions['childrenSortOptions']['orders']).map((e) => ({
                    label: e[groupOptions['childrenResponseTemplate']['label']],
                    value: e[groupOptions['childrenResponseTemplate']['value']]
                }));
            }
            else {
                obj['options'] = group[key].map((e) => ({
                    label: e[groupOptions['childrenResponseTemplate']['label']],
                    value: e[groupOptions['childrenResponseTemplate']['value']]
                }));
            }

            return obj;
        })
    }
    else {
        options = data.map((e) => ({
            label: e[responseTemplate['label']],
            value: e[responseTemplate['value']]
        }));

        if (sortAscending) {
            options = _.orderBy(options, 'label');
        }
    }

    return options;
}

/// <summary>
/// Author: Nelson
/// </summary>
export function constructErrorMessage(i18n, formInstance, key, extraParams = {}) {
    return formInstance.getFieldError(key).length > 0 && !stringIsNullOrEmpty(formInstance.getFieldError(key)[0]) ? i18n(formInstance.getFieldError(key)[0], { ...extraParams }) : undefined;
}

/// <summary>
/// Author: Nelson
/// </summary>
export function stringFormat(value = '', params = []) {
    var finalValue = value;

    for (var i = 0; i < params.length; i++) {
        finalValue = finalValue.replace(`{${i}}`, params[i]);
    }

    return finalValue;
}

/// <summary>
/// Author: Nelson
/// </summary>
export async function convertBase64ToFile(data = { fileName: '', contentType: '', content: '' }) {
    var response = await fetch(data.content);
    var fileData = await response.blob();

    return new File([fileData], data.fileName, { type: data.contentType });
}

/// <summary>
/// Author: Nelson
/// </summary>
export function getContentTypeFromBase64(base64String) {
    const base64Prefix = 'data:';
    const base64Data = base64String.split(',')[0];
    const contentType = base64Data.substring(base64Prefix.length, base64Data.indexOf(';'));

    return contentType;
}

/// <summary>
/// Author: Nelson
/// </summary>
export function getExtensionFromContentType(contentType) {
    return contentType.replace('image/', '.');
}

/// <summary>
/// Author: Nelson
/// </summary>
export function validatePhoneNumber(value) {
    return (!stringIsNullOrEmpty(value) && isValidPhoneNumber(value)) || 'Please enter valid phone number'
}