export const _SETUP_AUTH_HEADER = "setupAuthHeader";
export const _UPDATE_BIOMETRIC_DATA = "updateBiometricData";

/// <summary>
/// Author: Nelson
/// </summary>
export const setupAuthHeader = (headerConfig) => {
    return async dispatch => {
        dispatch({
            headerConfig: headerConfig,
            type: _SETUP_AUTH_HEADER
        });
    }
}