import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { stringIsNullOrEmpty } from '../../common/util';
import { CallbackResponseType, _STANDARD_MOBILE_VIEW_WIDTH_SIZE } from '../../common/constant';
import { Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { colorError, colorSuccess } from '../../shared/theme';
import { useTranslation } from "react-i18next";


/// <summary>
/// Author: Nelson
/// </summary>
const VerificationResult = () => {
    var _location = useLocation();
    var _timeoutRef = useRef(null);
    const { t } = useTranslation();

    const [result, setResult] = useState({});
    const [redirectUrl, setRedirectUrl] = useState('');

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        const REDIRECT_COUNTDOWN_IN_MS = 5000;

        var resultState = { ...(_location?.state ?? {}) };

        if (_location.state) {
            if (!stringIsNullOrEmpty(_location.state.content)) {
                if (_location.state.contentTypeId === CallbackResponseType._JAVASCRIPT) {
                    const dynamicFunction = new Function(removeFirstAndLastQuotes(_location.state.content));

                    dynamicFunction();
                }
                else if (_location.state.contentTypeId === CallbackResponseType._URL) {
                    setRedirectUrl(_location.state.content);
                    _timeoutRef.current = setTimeout(() => { window.location.replace(_location.state.content); }, REDIRECT_COUNTDOWN_IN_MS);
                }
            }
        }
        else {
            resultState = { ...resultState, status: false, title: t("PERMISSION_DENIED"), message: t("NOT_PERMITTED_ACCESS") };
        }

        setResult({ ...resultState });

        return () => {
            if (_timeoutRef.current) {
                clearTimeout(_timeoutRef.current);

                _timeoutRef.current = null;
            }
        }
    }, []);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function removeFirstAndLastQuotes(stringValue) {
        if (!stringIsNullOrEmpty(stringValue) && stringValue.startsWith('"') && stringValue.endsWith('"')) {
            stringValue = stringValue.substring(1, stringValue.length - 1);
        }

        return stringValue;
    }

    return (
        result &&
        <>
            {
                result.contentTypeId === CallbackResponseType._HTML && !stringIsNullOrEmpty(result.content) && <div dangerouslySetInnerHTML={result.content} />
            }
            {
                result.contentTypeId !== CallbackResponseType._HTML &&
                <div style={{ padding: '0 20px', height: '100%', maxWidth: _STANDARD_MOBILE_VIEW_WIDTH_SIZE, margin: '0 auto', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                     {
                        result.icon && <i className={`fas fa-${result.icon.name}`} style={{ color: result.icon.color, fontSize: '7.5rem' }}></i>
                    }
                    {
                        !result.icon &&
                        <>
                            {
                                !result.status &&
                                <CloseCircleFilled style={{ color: colorError, fontSize: '7.5rem' }} />
                            }
                            {
                                result.status &&
                                <CheckCircleFilled style={{ color: colorSuccess, fontSize: '7.5rem' }} />
                            }
                        </>
                    }
                    <div style={{ marginTop: '3vh' }}>
                        <Typography.Title level={1}>{result.title ?? ''}</Typography.Title>
                        {
                            !stringIsNullOrEmpty(result.message) && 
                                <div>
                                    <Typography.Title level={3} style={{ fontWeight: 'normal', marginTop: '-1vh', fontSize: 'medium', marginBottom: '7vh' }}>{result.message}</Typography.Title>
                                </div>
                            
                        }
                        {
                            !stringIsNullOrEmpty(redirectUrl) && 
                                <Typography.Text style={{ fontSize: 'small' }}>
                                    {t("REDIRECTED_SOON")} If it is not you can{' '}
                                    <Link to="#" onClick={() => window.location.replace(redirectUrl)} style={{ textDecoration: 'underline', color: 'blue' }}>{t("CLICK_HERE")}</Link>
                                </Typography.Text>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default VerificationResult;