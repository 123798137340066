import React, { useEffect, useState } from 'react';
import { Button, Spin, Typography } from 'antd';
import TransactionService from '../../services/transaction_service';
import { Page, SessionKey, VendorParamKey } from '../../common/constant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import PaymentMethodList from '../../components/payment_method_list';
import {
    handleNavigate
} from './utils/index';

/// <summary>
/// Author: SY
/// </summary>
const PaymentSelection = () => {
    const _transactionService = new TransactionService();
    const _navigate = useNavigate();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [params] = useSearchParams();
    const [amount, setAmount] = useState('');

    /// <summary>
    /// Author: SY
    /// </summary>
    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true);
                    sessionStorage.setItem(SessionKey._XCHANGE_KEY, params.get(VendorParamKey._XCHANGE_KEY));
                    var response = await _transactionService.initTransaction();
                   
                    setAmount(response.data.amount ?? 0);
                    setPaymentMethods(response.data.paymentSelections);

                    if (response.data.amount === 0 || !response.data.amount) {
                        throw '';
                    } else if (response.data.paymentSelections.length === 1 && response.data.amount > 0) {
                       handleNavigate(response.data.paymentSelections,response.data.paymentSelections[0].data,response.data.amount,_navigate);
                    }
                }
                catch (errorMsg) {
                    _navigate(Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL);
                } finally {
                    setLoading(false);
                }
            }
        )();
    }, [_navigate]);
    
    /// <summary>
    /// Author: SY
    /// </summary>
    const handlePaymentMethodClick = (method) => {
        setSelectedPaymentMethod(method.data);
    };

    return (
        <div style={{ padding: '5%', width: '90%', margin: 'auto' }}>
            {loading ? (
                <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />
            ) : (
                <>
                    <PaymentMethodList
                        paymentMethods={paymentMethods}
                        selectedPaymentMethod={selectedPaymentMethod}
                        onPaymentMethodClick={handlePaymentMethodClick}
                    />
                    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0px -2px 10px rgba(0,0,0,0.1)', padding: '2%', zIndex: 100 }}>
                        <Button
                            type="primary"
                            style={{ width: '96%', height: '50px', border: 'none', fontSize: '18px', fontWeight: 'bold' }}
                            onClick={() => handleNavigate(paymentMethods, selectedPaymentMethod, amount, _navigate)}
                            disabled={!selectedPaymentMethod}
                        >
                            {t("NEXT")}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default PaymentSelection;
