import axios from 'axios';
import { ApiKey, HttpStatusCode, Page, _BASE_URL } from './constant';
import { stringIsNullOrEmpty } from './util';

var _pendingRequest = [];
const _REQUEST_COOLDOWN = 3000;

/// <summary>
/// Author: Nelson
/// </summary>
async function interceptRequest(requestConfig) {
    checkPendingRequest(requestConfig);

    requestConfig.cancelToken = new axios.CancelToken((cancel) => {
        _pendingRequest.push({ url: requestConfig.url, removeRequest: cancel, requestTime: new Date().getTime() });
    });

    return requestConfig;
}

/// <summary>
/// Author: Nelson
/// </summary>
function interceptResponse(response) {
    _pendingRequest = [];

    return response[ApiKey._DATA_KEY];
}

/// <summary>
/// Author: Nelson
/// </summary>
function interceptError(error) {
    var returnData = {};

    if (error.response) {
        returnData[ApiKey._SUCCESS_KEY] = false;
        returnData[ApiKey._MESSAGE_KEY] = "Error " + error.response.status + " encounter, please contact admin for help";
        returnData[ApiKey._CODE_KEY] = error.response.status;
    }
    else if (!axios.isCancel(error)) {
        returnData[ApiKey._SUCCESS_KEY] = false;
        returnData[ApiKey._MESSAGE_KEY] = !stringIsNullOrEmpty(error["message"]) ? JSON.stringify(error["message"]) : "Error " + error.response.status + " encounter, please contact admin for help";
        returnData[ApiKey._CODE_KEY] = error.response.status;
    }
    else {
        returnData[ApiKey._SUCCESS_KEY] = false;
        returnData[ApiKey._MESSAGE_KEY] = "SERVER_IS_PROCESSING";
        returnData[ApiKey._CODE_KEY] = HttpStatusCode._INTERNAL_SERVER_ERROR;
    }

    return returnData;
}

/// <summary>
/// Author: Nelson
/// </summary>
const checkPendingRequest = (config) => {
    if (_pendingRequest.some(request => request.url == config.url && request.requestTime < _REQUEST_COOLDOWN)) {
        for (var i = 0; i < _pendingRequest.filter(request => request.url == config.url && request.requestTime < _REQUEST_COOLDOWN).length; i++) {
            _pendingRequest[i].removeRequest();
        }
    }
}

/// <summary>
/// Author: Nelson
/// </summary>
const ApiEngine = axios.create({
    baseURL: _BASE_URL,
    headers: {
        "content-type": ApiKey._APPLICATION_JSON
    },
    timeout: 60000
});

/// <summary>
/// Author: Nelson
/// </summary>
ApiEngine.interceptors.request.use(
    config => interceptRequest(config),
    error => interceptError(error),
);

/// <summary>
/// Author: Nelson
/// </summary>
ApiEngine.interceptors.response.use(
    response => interceptResponse(response),
    error => interceptError(error),
);

export default ApiEngine;