export const _SET_MODE = 'setMode';

/// <summary>
/// Author: SY
/// </summary>
export const setMode = (mode) => ({
    type: _SET_MODE,
    payload: mode,
});

