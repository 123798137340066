import React, { useEffect } from 'react';
import { SessionKey, _STANDARD_MOBILE_VIEW_WIDTH_SIZE } from '../../common/constant';
import { CloseCircleFilled } from '@ant-design/icons';
import { Typography, Space } from 'antd';
import { useTranslation } from "react-i18next";
import { colorError } from '../../shared/theme';

/// <summary>
/// Author: Nelson
/// </summary>
const AuthExpiry = () => {
    const { t } = useTranslation();

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        sessionStorage.removeItem(SessionKey._XCHANGE_KEY);
        sessionStorage.removeItem(SessionKey._PHONE_NUMBER);
    }, [])

    return (
        <div style={{ padding: '0 20px', height: '100%', maxWidth: _STANDARD_MOBILE_VIEW_WIDTH_SIZE, margin: '0 auto', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <CloseCircleFilled style={{ color: colorError, fontSize: '7.5rem' }} />
            <Space direction="vertical" style={{ marginTop: '3vh', textAlign: 'center' }}>
                <Typography.Title level={1}>{t("OOPS")}</Typography.Title>
                <Typography.Title level={4} style={{ marginTop: 0, fontWeight: 'normal' }}>{t("AUTHENTICATION_EXPIRED")}</Typography.Title>
            </Space>
        </div>
    )
};

export default AuthExpiry;