import React, { useEffect, useState } from 'react';
import { Typography, Button, Input, Spin } from 'antd';
import TransactionService from '../../services/transaction_service';
import { Page, SessionKey, VendorParamKey } from '../../common/constant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import PaymentMethodList from '../../components/payment_method_list';
import {
    handleNavigate
} from './utils/index';

/// <summary>
/// Author: SY
/// </summary>
const TopupSelection = () => {
    const _transactionService = new TransactionService();
    const _navigate = useNavigate();
    const [params] = useSearchParams();
    const [amount, setAmount] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    /// <summary>
    /// Author: SY
    /// </summary>
    useEffect(() => {
        (
            async () => {
                try {
                    setLoading(true);
                    sessionStorage.setItem(SessionKey._XCHANGE_KEY, params.get(VendorParamKey._XCHANGE_KEY));
                    var response = await _transactionService.initTransaction();

                    setPaymentMethods(response.data.paymentSelections);
                    setAmount(response.data.amount ?? 0);
                    if (response.data.paymentSelections.length === 1 || response.data.amount > 0) {
                        throw '';
                    }
                }
                catch (errorMsg) {
                    _navigate(Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL);
                } finally {
                    setLoading(false);
                }
            }
        )();
    }, []);

    /// <summary>
    /// Author: SY
    /// </summary>
    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    /// <summary>
    /// Author: SY
    /// </summary>
    const handleButtonClick = (value) => {
        setAmount(value);
    };

    /// <summary>
    /// Author: SY
    /// </summary>
    const handlePaymentMethodClick = (method) => {
        setSelectedPaymentMethod(method.data);
    };

    /// <summary>
    /// Author: SY
    /// </summary>
    const BUTTON_AMOUNTS = ['10', '20', '50', '100', '200'];

    return (
        <div style={{ padding: '5%', width: '90%', margin: 'auto' }}>
            {loading ? (
                <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />
            ) : (
                <>
                    <div style={{ flex: '1', paddingBottom: '100px' }}>
                        <Typography.Title level={3} style={{ marginBottom: '10px' }}>{t('TOP_UP_BALANCE')}</Typography.Title>
                        <Typography.Text>{t("ENTER_TOP_UP_AMOUNT")}</Typography.Text>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <Input
                                addonBefore="RM"
                                value={amount}
                                onChange={handleAmountChange}
                                type="number"
                                style={{ width: '100%' }}
                                placeholder="0.00"
                                inputMode='numeric'
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '20px' }}>
                            {
                                BUTTON_AMOUNTS.map((value) => (
                                    <Button
                                        key={value}
                                        type={amount === value ? 'primary' : 'default'}
                                        style={{ width: '30%', height: '50px', marginBottom: '10px', fontWeight: 'bold' }}
                                        onClick={() => handleButtonClick(value)}
                                    >
                                        RM {value}
                                    </Button>
                                ))
                            }
                            <Button
                                key="Other"
                                type={amount > 0 && !BUTTON_AMOUNTS.includes(amount) ? 'primary' : 'default'}
                                style={{ width: '30%', height: '50px', marginBottom: '10px', fontWeight: 'bold' }}
                                onClick={() => handleButtonClick('')}
                            >
                                {t("OTHER")}
                            </Button>
                        </div>
                        <PaymentMethodList
                            paymentMethods={paymentMethods}
                            selectedPaymentMethod={selectedPaymentMethod}
                            onPaymentMethodClick={handlePaymentMethodClick}
                        />
                    </div>
                    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#fff', boxShadow: '0px -2px 10px rgba(0,0,0,0.1)', padding: '2%', zIndex: 100 }}>
                        <Button
                            type="primary"
                            style={{ width: '96%', height: '50px', border: 'none', fontSize: '18px', fontWeight: 'bold' }}
                            onClick={() => handleNavigate(paymentMethods, selectedPaymentMethod, amount, _navigate)}
                            disabled={amount <= 0 || !selectedPaymentMethod}
                        >
                            {t("NEXT")}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default TopupSelection;
