export const colorPrimary = '#3d3868';
export const colorSuccess = '#5fff5f';
export const colorError = '#ff5f5f';
export const colorWarning = '#ff9000';

export const theme = {
    token: {
        colorPrimary: colorPrimary,
        colorError: colorError,
        colorErrorActive: '#d9363e',
        colorErrorHover: '#ff7875',
        colorLink: '#1677ff',
        colorLinkActive: '#0958d9',
        colorLinkHover: '#69b1ff',
        colorSuccess: colorSuccess,
        colorWarning: colorWarning,
        fontSize: '14px',
    },
    components: {
        Button: {
            defaultBorderColor: colorPrimary,
            borderRadius: '10px'
        },
        Typography: {
            fontSizeHeading1: '32px',
            fontSizeHeading2: '28px',
            fontSizeHeading3: '24px',
            fontSizeHeading4: '20px',
            fontSizeHeading5: '16px',
            colorText: 'rgba(0, 0, 0, 0.88)',
            colorTextDescription: 'rgba(0, 0, 0, 0.45)',
            colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
            colorTextHeading: 'rgba(0, 0, 0, 0.88)',
        },
        Input: {
            paddingBlock: '11px'
        }
    }
}
//refer https://ant.design/theme-editor#component-style