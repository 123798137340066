import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language, SessionKey } from "../common/constant";
import EnglishTranslation from "./en.json";
import MandarinTranslation from "./zh.json";
import MalayTranslation from "./ms.json";

const _RESOURCES = {};
_RESOURCES[Language._ENGLISH] = { common: EnglishTranslation };
_RESOURCES[Language._CHINESE] = { common: MandarinTranslation };
_RESOURCES[Language._BAHASA_MELAYU] = { common: MalayTranslation };

i18n.use(initReactI18next).init({
    defaultNS: "common",
    debug: false,
    resources: _RESOURCES,
    lng: (!(typeof window === "undefined") ? sessionStorage.getItem(SessionKey._LANGUAGE) : '') || Language._ENGLISH,
    fallbackLng: [Language._ENGLISH, Language._CHINESE],
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});
export default i18n;