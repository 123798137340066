import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Row, Col } from 'antd';
import { useTranslation } from "react-i18next";
import { PaymentStatus } from '../../common/constant';

/// <summary>
/// Author: SY
/// </summary>
const PaymentResult = () => {
    const location = useLocation();
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [refNo, setRefNo] = useState(null);
    const [message, setMessage] = useState(null);
    const { t } = useTranslation();
    const STATUS_SUCCESS = 'SUCCESS';
    const STATUS_ACCEPTED = 'ACCEPTED';
    const COUNT_DOWN_TIME = 5;
    const [countdown, setCountdown] = useState(COUNT_DOWN_TIME);
    const [content, setContent] = useState(null);

    /// <summary>
    /// Author: SY
    /// </summary>
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let status = query.get('status');
        const refNo = query.get('refNo');
        const msg = query.get('msg');
        const responseCallbackUrl = query.get('responseCallbackUrl');

        const { response } = location.state || {};
      
        if (location.search) {
            window.history.replaceState(
                { ...location.state },
                '',
                window.location.pathname
            );
        }

        if (typeof status === 'string') {
            if (status.toUpperCase() === STATUS_SUCCESS || status.toUpperCase() === STATUS_ACCEPTED) {
                status = PaymentStatus._SUCCESS;
            } else if (!isNaN(status)) {
                status = parseInt(status)
            } else {
                status = PaymentStatus._FAILED;
            }
        }

        const finalStatus = status || response?.data?.status;
        const finalRefNo = refNo || response?.data?.refNo;
        let finalCallbackUrl = responseCallbackUrl || response?.data?.callbackResponse?.content;
        setPaymentStatus(finalStatus);
        setRefNo(finalRefNo);
        if (response) {
            const displayMessage = response.success ? response.data.message : response.message;
            setMessage(displayMessage);
        } else {
            setMessage(msg);
        }

        if (finalCallbackUrl) {
            setContent(finalCallbackUrl);

            const intervalId = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        clearInterval(intervalId);
                        window.location.replace(finalCallbackUrl);
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(intervalId);
        } else {
            setContent(null);
        }
    }, [location.search, location.state]);

    return (
        <Row justify="center" style={{ marginTop: '50px' }}>
            <Col xs={22} sm={16} md={12} lg={10}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Typography.Title level={3}>{t('PAYMENT_RESULT')}</Typography.Title>
                    {paymentStatus === PaymentStatus._SUCCESS ? (
                        <>
                            <Typography.Text type="success">
                                {t("PAYMENT_SUCCESS")}!
                            </Typography.Text>
                            <Typography.Text type="success" style={{ display: 'block', marginTop: '10px' }}>
                                {message}
                            </Typography.Text>
                        </>
                    ) : (
                        <>
                            <Typography.Text type="danger">
                                {t("PAYMENT_FAILED")} !
                            </Typography.Text>
                            <Typography.Text type="danger" style={{ display: 'block', marginTop: '10px' }}>
                                {t("ERROR")}: {message}
                            </Typography.Text>
                        </>
                    )}
                    <Typography.Text style={{ display: 'block', marginTop: '20px' }}>
                        {t("REFERENCE_NUMBER")}: {refNo}
                    </Typography.Text>
                    {
                        content && (
                            <Typography.Text type="secondary" style={{ display: 'block', marginTop: '10px' }}>
                                {t("AUTO_REDIRECT_MESSAGE", { seconds: countdown })}
                            </Typography.Text>
                        )
                    }
                </div>
            </Col>
        </Row>
    );
};

export default PaymentResult;
