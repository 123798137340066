import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { stringIsNullOrEmpty } from '../../common/util';
import { _STANDARD_MOBILE_VIEW_WIDTH_SIZE, Page } from '../../common/constant';
import { Button, Space } from 'antd';

/// <summary>
/// Author: YJ
/// </summary>
const ProcessTopUp = () => {
    var _location = useLocation();
    var _navigate = useNavigate();

    const { txnId, amount } = useParams();

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function onBtnClick(success = true) {
        var status = success ? "00" : "11";

        _navigate(Page._TOPUP_RESULT_URL.replace(":orderId", txnId).replace(":status", status).replace(":amount", amount).replace(":currency", "myr"));
    }

    return (
        <div style={{ padding: '0 20px', height: '100%', maxWidth: _STANDARD_MOBILE_VIEW_WIDTH_SIZE, margin: '0 auto', textAlign: 'center', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Space direction='horizontal'>
                <Button type="primary" success onClick={() => { onBtnClick(true) }}>Success</Button>
                <Button type="primary" danger onClick={() => { onBtnClick(false) }}>Fail</Button>
            </Space>
        </div>
    );
};

export default ProcessTopUp;