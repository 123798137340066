
import Content from "./routes/content";
import { PageSettings } from './routes/setting';
import React from 'react';
import { connect } from "react-redux";
import { _DEFAULT_RESULT_PARAMS } from "./pages/result/verification_result";
import { _VERIFICATION_RESULT_MSG } from "./pages/verification/biometric";

/// <summary>
/// Author: Nelson
/// </summary>
const App = () => {
    return (
        <PageSettings.Provider value={{}}>
            <Content />
        </PageSettings.Provider>
    );
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps, null)(App);