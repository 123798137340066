import { Page, SessionKey, VendorParamKey, BiometricStatus } from '../../common/constant';
import { isObjectEmpty, stringIsNullOrEmpty } from '../../common/util';
import MemberService from '../../services/member_service';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Typography } from 'antd';
import PuffLoader from "react-spinners/PuffLoader";
import { colorSuccess, colorError, colorWarning } from '../../shared/theme';
/// <summary>
/// Author: Nelson
/// </summary>
const Biometric = () => {
    var _navigate = useNavigate();
    var _sessionRef = useRef(null);

    const [params] = useSearchParams();

    const [loading, setLoading] = useState(false);

    const _memberService = new MemberService();

    const _RESULT_MESSAGE = {
        [BiometricStatus._PENDING]: {
            title: 'Account Verification Request Submitted',
            message: 'You have already submitted an account verification request. Please wait for the verification process to be completed.',
            icon: {
                name: 'check-circle',
                color: colorSuccess,
            }
        },
        [BiometricStatus._QUALIFIED]: {
            title: 'Verified Successfully',
            message: 'Your account is verified and ready to go.',
            icon: {
                name: 'check-circle',
                color: colorSuccess
            }
        },
        [BiometricStatus._UNQUALIFIED]: {
            title: 'Verified Unsuccessful',
            message: 'We regret to inform you that your verification request has not been approved. Please submit another verification request.',
            icon: {
                name: 'times-circle',
                color: colorError
            }
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        window.addEventListener('blur', loseFocus);

        return () => window.removeEventListener('blur', loseFocus);
    }, [loading])

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function loseFocus(e) {
        if (!loading) {
            e.preventDefault();
            e.stopPropagation();

            await endBiometricSession();
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function endBiometricSession(message, title = '', icon = {}) {
        var contentState = {
            content: '',
            title: !stringIsNullOrEmpty(title) ? title : 'Session Ended',
            message: !stringIsNullOrEmpty(message) ? message : 'Please complete the verification before leaving this screen. You may try again.',
            contentTypeId: null,
            icon: !isObjectEmpty(icon) ? icon : {
                name: 'exclamation-circle',
                color: colorWarning
            }
        }

        if (_sessionRef.current) {
            clearTimeout(_sessionRef.current);
        }

        if (!stringIsNullOrEmpty(sessionStorage.getItem(SessionKey._XCHANGE_KEY))) {
            try {
                var response = await _memberService.terminateBiometricSession();

                if (response.data.callback) {
                    contentState.content = response.data.callback.content;
                    contentState.contentTypeId = response.data.callback.typeId;
                }
            } catch (err) {
                // do nothing
            } finally {
                window.OzLiveness.close();
            }

            _navigate(Page._VERIFICATION_RESULT_URL, { replace: true, state: { ...contentState } });
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        (
            async () => {
                const VALID_SESSION_DURAITON = 120000;

                try {
                    var key = params.get(VendorParamKey._XCHANGE_KEY);

                    if (stringIsNullOrEmpty(key)) {
                        throw 'Invalid session';
                    }

                    sessionStorage.setItem(SessionKey._XCHANGE_KEY, key);

                    window.history.replaceState({}, document.title, Page._BIOMETRIC_VERIFICATION_MAIN_URL);

                    var response = await _memberService.initBiometricSession();
                    var token = getToken(response.data.token);

                    if (!stringIsNullOrEmpty(token)) {
                        openCamera(token);

                        _sessionRef.current = setTimeout(async () => {
                            await endBiometricSession('Please complete the verification within 2 minutes. You may try again.');
                        }, VALID_SESSION_DURAITON)
                    }
                    else {
                        throw 'Permission Denied';
                    }
                } catch (error) {
                    _navigate(Page._VERIFICATION_RESULT_URL, { replace: true });
                }
            })();
    }, []);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function getToken(token) {
        const TOKEN_SEPARATOR = "@";
        const TOKEN_EXPIRY_FORMAT = "YYYYMMDDHHmmssSSSSSSSSS";
        const TOKEN_EXPIRY_POSITION = 1;
        const TOKEN_POSITION = 0;

        var encryptedBytes = atob(token);
        var keyBytes = Array.from(process.env.REACT_APP_BIOMETRIC_TOKEN_KEY, c => c.charCodeAt(0));

        var decryptedBytes = [];

        for (let i = 0; i < encryptedBytes.length; i++) {
            decryptedBytes.push(encryptedBytes.charCodeAt(i) ^ keyBytes[i % keyBytes.length]);
        }

        var result = String.fromCharCode.apply(null, decryptedBytes);

        var values = result.split(TOKEN_SEPARATOR);
        var expiryDate = moment(values[TOKEN_EXPIRY_POSITION], TOKEN_EXPIRY_FORMAT);

        if (moment().isAfter(expiryDate)) {
            result = '';
        }
        else {
            result = values[TOKEN_POSITION];
        }

        return result;
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function openCamera(token) {
        try {
            window.OzLiveness.open({
                lang: 'en',
                token,
                action: ['video_selfie_best', 'video_selfie_right', 'video_selfie_left'],
                style: {
                    backgroundOutsideFrame: {
                        color: 'black'
                    }
                },
                result_mode: ['full'],
                params: { 'extract_best_shot': true, 'result_mode': ['full'] },
                on_complete: onCallback
            });
        } catch (err) {
            throw err;
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function onCallback(result) {
        var contentState = {
            content: '',
            title: '',
            message: '',
            contentTypeId: null
        }

        if (_sessionRef.current) {
            clearTimeout(_sessionRef.current);
        }

        setLoading(true);

        try {
            if (
                !isObjectEmpty(result)
                &&
                !isObjectEmpty(result.analyses)
                &&
                !isObjectEmpty(result.analyses.quality)
                &&
                result.analyses.quality.state === 'finished'
            ) {
                var response = await _memberService.submitBiometricRequest({ validationId: result.folder_id });

                sessionStorage.removeItem(SessionKey._XCHANGE_KEY);

                if (response.data && response.data.callback) {
                    var msgObj = _RESULT_MESSAGE[response.data.status];

                    contentState.content = response.data.callback.content;
                    contentState.title = msgObj.title;
                    contentState.message = msgObj.message;
                    contentState.icon = msgObj.icon;
                    contentState.contentTypeId = response.data.callback.typeId;

                    _navigate(Page._VERIFICATION_RESULT_URL, { state: { ...contentState }, replace: true });
                }
                else if (response.data.status) {
                    var msgObj = _RESULT_MESSAGE[response.data.status];

                    contentState.title = msgObj.title;
                    contentState.message = msgObj.message;
                    contentState.icon = msgObj.icon;

                    _navigate(Page._VERIFICATION_RESULT_URL, { state: { ...contentState }, replace: true });
                }
            }
            else {
                throw 'Failed to submit face verification request';
            }
        }
        catch (error) {
            console.error(error);
            var msgObj = _RESULT_MESSAGE[BiometricStatus._UNQUALIFIED];

            await endBiometricSession(msgObj.message, msgObj.title, msgObj.icon);
        }

        setLoading(false);
    }

    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flexDirection: 'column' }}>
            {
                loading &&
                <>
                    <PuffLoader color='#36d7b7' loading size={70} />
                    <Typography.Title level={4} style={{ fontWeight: 'normal', textAlign: 'center', marginTop: '1.2rem', marginBottom: '0' }}>
                        Please wait as we process your request...
                    </Typography.Title>
                    <Typography.Title level={4} style={{ fontWeight: 'normal', textAlign: 'center', marginTop: '0', paddingTop: '0' }}>
                        <strong>DO NOT</strong> close or leave this page
                    </Typography.Title>
                </>
            }
        </div>
    );
}

export default Biometric;