import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import vendorState from '../application/reducer/vendor_reducer.js';
import modeState from '../application/reducer/mode_reducer.js';

/// <summary>
/// Author: Nelson
/// </summary>
const store = configureStore({
    reducer: {
        vendorState: vendorState,
        modeState: modeState
    },
    middleware: [thunk]
});

export default store;