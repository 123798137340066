import { _SET_MODE } from "../action/mode_action";

/// <summary>
/// Author: SY
/// </summary>
const _INITIAL_STATE = {
    mode: localStorage.getItem('mode') || null,
};

/// <summary>
/// Author: SY
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    console.log(action.type)
    switch (action.type) {
        case _SET_MODE:
            localStorage.setItem('mode', action.payload);
            return {
                ...state,
                mode: action.payload,
            }
        default:
            return state;
    }
}