import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Button, Typography, Spin } from 'antd';
import TransactionService from '../../services/transaction_service';
import { Page, PaymentStatus } from '../../common/constant';
import { useTranslation } from "react-i18next";
import { colorError } from '../../shared/theme';

/// <summary>
/// Author: SY
/// </summary>
const Pin = () => {
    const PIN_LENGTH = 6;
    const STEP_ENTER_PIN = 1;
    const STEP_CONFIRM_PIN = 2;
    const DELETE_CONTENT_BACKWARD = 'deleteContentBackward';
    const ID_PIN = 'pin';
    const ID_CONFIRM_PIN = 'confirm-pin';

    const _transactionService = new TransactionService();
    const [pinExists, setPinExists] = useState(null);
    const [loading, setLoading] = useState(true);
    const [transactionId, setTransactionId] = useState('');
    const [error, setError] = useState('');
    const _navigate = useNavigate();
    const [pin, setPin] = useState(['', '', '', '', '', '']);
    const [confirmPin, setConfirmPin] = useState(['', '', '', '', '', '']);
    const [step, setStep] = useState(STEP_ENTER_PIN);
    const _location = useLocation();
    const { amount, paymentMethod } = _location.state || {};
    const { t } = useTranslation();

    /// <summary>
    /// Author: SY
    /// </summary>
    useEffect(() => {
        const initPayment = async () => {
            try {
                setLoading(true);
                var response = await _transactionService.initPayment({
                    amount: amount,
                    paymentCode: paymentMethod['data'],
                    methodId: paymentMethod['methodId'],
                });

                setTransactionId(response.data);
                const checkPin = await _transactionService.checkPinExist();
                setPinExists(checkPin.data);
                if (checkPin.data) {
                    setStep(STEP_ENTER_PIN);
                }
            }
            catch (errorMsg) {
                var contentState = {
                    title: t("PAYMENT_FAILED"),
                    message: t("CONTACT_ADMIN_FOR_HELP"),
                    icon: {
                        name: 'times-circle',
                        color: colorError
                    }
                };
                _navigate(Page._VERIFICATION_RESULT_URL, { state: { ...contentState }, replace: true });
            } finally {
                setLoading(false);
            }
        };

        initPayment();
    }, []);

    /// <summary>
    /// Author: SY
    /// </summary>
    const handlePinChange = (e, index, isConfirm = false) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {

            const newPin = isConfirm ? [...confirmPin] : [...pin];
            newPin[index] = value;
            isConfirm ? setConfirmPin(newPin) : setPin(newPin);

            if (value && index < PIN_LENGTH - 1) {
                document.getElementById(`${isConfirm ? ID_CONFIRM_PIN : ID_PIN}-${index + 1}`).focus();
            } else if (!value && index > 0 && e.nativeEvent.inputType === DELETE_CONTENT_BACKWARD) {
                document.getElementById(`${isConfirm ? ID_CONFIRM_PIN : ID_PIN}-${index - 1}`).focus();
            }
        }
    };

    /// <summary>
    /// Author: SY
    /// </summary>
    const handlePinSubmit = async () => {
        const completePin = pin.join('');
        const completeConfirmPin = confirmPin.join('');

        if (!pinExists) {
            if (step === STEP_ENTER_PIN) {
                if (completePin.length === PIN_LENGTH) {
                    setStep(STEP_CONFIRM_PIN);
                    setError('');
                } else {
                    setError(t("PIN_REQUIRED"));
                }
            } else if (step === STEP_CONFIRM_PIN) {
                if (completeConfirmPin.length === PIN_LENGTH) {
                    if (completePin !== completeConfirmPin) {
                        setError(t("PIN_MISMATCH"));
                    } else {
                        const params = {
                            pin: completePin,
                            refNo: transactionId
                        };
                        try {
                            const response = await _transactionService.createPin(params);
                            if (response.success) {
                                handleApiResponse(response);
                            } else {
                                handleApiError(response);
                            }
                        } catch (error) {
                            handleApiError(error);
                        }
                    }
                } else {
                    setError(t("PIN_REQUIRED"));
                }
            }
        } else {
            if (completePin.length === PIN_LENGTH) {
                const params = {
                    pin: completePin,
                    refNo: transactionId
                };
                try {
                    const response = await _transactionService.validatePin(params);
                    if (response.success) {
                        handleApiResponse(response);
                    } else {
                        handleApiError(response);
                    }
                } catch (error) {
                    handleApiError(error);
                }
            } else {
                setError(t("PIN_REQUIRED"));
            }
        }
    };

    /// <summary>
    /// Author: SY
    /// </summary>
    const handleApiResponse = (response) => {
        const { status, message, refNo } = response.data;
        _navigate(Page._PAYMENT_RESULT, {
            replace: true,
            state: { response }
        });
    };

    /// <summary>
    /// Author: SY
    /// </summary>
    const handleApiError = (response) => {
        _navigate(Page._PAYMENT_RESULT, {
            replace: true,
            state: { response }
        });
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f2f5'
        }}>
            {loading ? (
                <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />
            ) :
                <div style={{
                    maxWidth: '400px',
                    width: '100%',
                    padding: '20px',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                }}>
                    <Typography.Title level={3}>PIN</Typography.Title>
                    <Typography.Text style={{ display: 'block', marginBottom: '20px' }}>
                        {pinExists ? t("PIN_LINKED_EWALLET") : (step === STEP_ENTER_PIN ? t("CREATE_NEW_PIN") : t("CONFIRM_NEW_PIN"))}
                    </Typography.Text>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px'
                    }}>
                        {Array.from({ length: PIN_LENGTH }).map((_, i) => (
                            <Input
                                key={i}
                                id={`${step === STEP_ENTER_PIN ? ID_PIN : ID_CONFIRM_PIN}-${i}`}
                                type="password"
                                inputMode='numeric'
                                maxLength={1}
                                value={step === STEP_ENTER_PIN ? pin[i] : confirmPin[i]}
                                onChange={(e) => handlePinChange(e, i, step === STEP_CONFIRM_PIN)}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    fontSize: '24px',
                                    textAlign: 'center',
                                    borderRadius: '4px',
                                    border: '1px solid #d9d9d9',
                                }}
                            />
                        ))}
                    </div>
                    {error && <Typography.Text type="danger" style={{ display: 'block', marginBottom: '20px' }}>{error}</Typography.Text>}
                    <Button type="primary" onClick={handlePinSubmit} block style={{ marginBottom: '20px' }}>
                        {step === STEP_ENTER_PIN ? t("NEXT") : t("SUBMIT")}
                    </Button>
                </div>}
        </div>
    );
};

export default Pin;