import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Spin, Typography, Form, Image } from 'antd';
import PhoneNumber from '../../components/phone_number';
import { stringIsNullOrEmpty, validatePhoneNumber } from '../../common/util';
import { Controller, useForm } from 'react-hook-form';
import MemberService from '../../services/member_service';
import { ApiKey, HttpStatusCode, Page, SessionKey, VendorParamKey } from '../../common/constant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Nelson
/// </summary>
const Authenticate = () => {
    const _DEFAULT_SECONDS_TIMEOUT = '59';
    const _OTP_EXPECTED_LENGTH = 6;

    const _STEP = {
        PhoneNumber: 1,
        Otp: 2
    }

    var _timeoutRef = useRef(null);

    const [params] = useSearchParams();
    const [seconds, setSeconds] = useState('');
    const [vendor, setVendor] = useState({});
    const [showImage, setShowImage] = useState(false);
    const [step, setStep] = useState(_STEP.PhoneNumber);
    const [done, setDone] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    const _navigate = useNavigate();
    const { control, handleSubmit, reset, unregister, setError } = useForm();

    const _memberService = new MemberService();

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        (
            async () => {
                try {
                    sessionStorage.setItem(SessionKey._XCHANGE_KEY, params.get(VendorParamKey._XCHANGE_KEY));

                    var response = await _memberService.initAuth();

                    setVendor(response.data);
                    setDone(true);
                }
                catch (errorMsg) {
                    _navigate(Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL);
                }
            }
        )();

        return () => {
            setVendor({});
            reset();
            unregister(['otp', 'phoneNumber']);
            setDone(false);
            setShowImage(false);
            setSeconds('');
            setSubmitted(false);
            setStep(_STEP.PhoneNumber);

            if (_timeoutRef.current != null) {
                clearTimeout(_timeoutRef.current);

                _timeoutRef.current = null;
            }
        }
    }, []);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(sessionStorage.getItem(SessionKey._PHONE_NUMBER)) && !stringIsNullOrEmpty(seconds)) {
            _timeoutRef.current = setTimeout(() => {
                var convertedInt = parseInt(seconds);

                if (convertedInt >= 0) {
                    setSeconds((convertedInt - 1).toString().padStart(2, '0'));
                }
            }, 1000);
        }
    }, [sessionStorage.getItem(SessionKey._PHONE_NUMBER), seconds]);

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function onSubmit(data) {
        setSubmitted(true);

        if (step === _STEP.PhoneNumber) {
            var phoneNumber = data.phoneNumber;

            setSeconds('');
            unregister('phoneNumber');
            setStep(_STEP.Otp);

            await sendOtp(phoneNumber);

            sessionStorage.setItem(SessionKey._PHONE_NUMBER, phoneNumber);

            setSubmitted(false);
        }
        else {
            try {
                var response = await _memberService.verifyAuthOtp(data.otp);

                if (response[ApiKey._CODE_KEY] !== HttpStatusCode._OK) {
                    throw response[ApiKey._MESSAGE_KEY];
                }

                // check if verify face is required
                if (response[ApiKey._SUCCESS_KEY]) {
                    sessionStorage.removeItem(SessionKey._PHONE_NUMBER);

                    if (response[ApiKey._DATA_KEY]['verified']) {
                        sessionStorage.removeItem(SessionKey._XCHANGE_KEY);

                        window.location.replace(response[ApiKey._DATA_KEY]['callback']['content']);
                    }
                    else {
                        _navigate(`${Page._BIOMETRIC_VERIFICATION_MAIN_URL}?key=${response[ApiKey._DATA_KEY]['key']}`, { replace: true });
                    }
                }
                else {
                    setError('otp', { message: 'Invalid OTP to be verified' });
                    setSubmitted(false);
                }
            }
            catch (error) {
                _navigate(Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL, { replace: true });
            }
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function sendOtp(phoneNumber) {
        try {
            var response = await _memberService.requestAuthOtp(phoneNumber.replace('+', ''));

            setSeconds((response.data ? response.data : _DEFAULT_SECONDS_TIMEOUT).toString().padStart(2, '0'));
        }
        catch {
            _navigate(Page._VENDOR_ACC_AUTHENTICATE_EXPIRED_URL, { replace: true });
        }
    }

    /// <summary>
    /// Author: Nelson
    /// </summary>
    function cleanup() {
        reset();
        unregister('otp');
        setStep(_STEP.PhoneNumber);
        sessionStorage.removeItem(SessionKey._PHONE_NUMBER);

        if (!stringIsNullOrEmpty(seconds) && parseInt(seconds) <= 0) {
            setSeconds('');
        }
    }

    return (
        <div className='auth-container'>
            {
                done && vendor &&
                <div className='auth-data-wrapper'>
                    <div style={{ padding: '10px 0 10px 20px', borderBottom: '1px solid rgb(201, 201, 201)' }}>
                        <Typography.Text style={{ fontSize: 'small' }}>{t("SIGN_IN_WITH")} {process.env.REACT_APP_NAME}</Typography.Text>
                    </div>
                    {
                        step === _STEP.Otp &&
                        <i className="fas fa-arrow-left back" onClick={() => { if (!submitted) { cleanup(); } }} />
                    }
                    <div className='auth-data-logo-wrapper'>
                        <Image alt={'vendor-logo'} onLoad={() => setShowImage(true)} src={vendor.image}
                            style={{ opacity: showImage ? '1' : '0' }} />
                    </div>
                    <div className='auth-data-title'>
                        {
                            step === _STEP.Otp &&
                            <>
                                <Typography.Title level={3}>{t("MESSAGE_SENT")}</Typography.Title>
                                <Typography.Text>{sessionStorage.getItem(SessionKey._PHONE_NUMBER)}</Typography.Text>
                            </>
                        }
                        {
                            step === _STEP.PhoneNumber &&
                            <>
                                <Typography.Title level={3}>{t("SIGN_IN")}</Typography.Title>
                                <Typography.Text>{t("TO_CONTINUE_TO") + (vendor.name ?? '')}</Typography.Text>
                            </>
                        }
                    </div>
                    <div className='auth-data-input-wrapper'>
                        {
                            step === _STEP.Otp &&
                            <Controller
                                control={control}
                                rules={{ required: 'Please verify with valid OTP', minLength: { value: _OTP_EXPECTED_LENGTH, message: 'Please verify with valid OTP' } }}
                                name={'otp'}
                                defaultValue={''}
                                render={({ fieldState, field }) => {
                                    return (
                                        <div className='input-container'>
                                            <div className="input-wrapper">
                                                <input type="text" name={field.name} required pattern="[0-9]*" inputMode="numeric" defaultValue={field.value} placeholder=" " onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '').substring(0, 6);

                                                    if (e.target.value.length <= 6) {
                                                        field.onChange(e.target.value);
                                                    }
                                                }} id='input' />
                                                <label htmlFor='input' className='placeholder'>{t("ONE_TIME_PASSWORD")}</label>
                                            </div>
                                            {fieldState.error && <span className='form-error'>{fieldState.error.message}</span>}
                                        </div>
                                    )
                                }} />
                        }
                        {
                            step === _STEP.PhoneNumber &&
                            <Controller
                                control={control}
                                rules={{ validate: validatePhoneNumber }}
                                name={'phoneNumber'}
                                render={({ fieldState, field }) => {
                                    return (
                                        <PhoneNumber name={field.name} error={fieldState.error} value={field.value} onChange={(value) => field.onChange(value)} />
                                    )
                                }} />
                        }
                    </div>
                    <div className='auth-data-submit-wrapper'>
                        {
                            step === _STEP.Otp &&
                            <Button disabled={submitted || stringIsNullOrEmpty(seconds) || parseInt(seconds) >= 0} onClick={async () => { setSeconds(''); await sendOtp(sessionStorage.getItem(SessionKey._PHONE_NUMBER)); }}>
                                {t("RESEND") + (stringIsNullOrEmpty(seconds) || parseInt(seconds) < 0 ? '' : (' (' + seconds + ')'))}
                            </Button>
                        }

                        <Button type="primary" onClick={() => handleSubmit(onSubmit)()}>
                            {
                                submitted ?
                                    <Spin indicator={<LoadingOutlined spin style={{ color: 'white' }} />} size="small" />
                                    :
                                    step === _STEP.PhoneNumber ? t("NEXT") : t("SUBMIT")}
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
};

export default Authenticate;